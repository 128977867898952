//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Cookiebot',
  props: {
    file: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    type() {
      if (
        (this.file.properties &&
          this.file.properties.mimeType.includes('youtube')) ||
        (this.file.mimeType && this.file.mimeType.includes('youtube'))
      ) {
        return 'youtube'
      } else if (
        (this.file.properties &&
          this.file.properties.mimeType.includes('vimeo')) ||
        (this.file.mimeType && this.file.mimeType.includes('vimeo'))
      ) {
        return 'vimeo'
      } else {
        return false
      }
    },
  },
  methods: {
    activateService() {
      if (process.browser && window.Cookiebot)
        window.Cookiebot.submitCustomConsent(true, true, true)
    },
    openModal() {
      if (process.browser && window.Cookiebot) window.Cookiebot.show()
    },
  },
}
