// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.ce-media-image_EIw6Z{\n  display:block;\n  width:100%\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ce-media-image": "ce-media-image_EIw6Z"
};
module.exports = ___CSS_LOADER_EXPORT___;
