//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Download from 'assets/images/download.svg?inline'

export default {
  components: {
    Download,
  },
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    color: {
      type: String,
      required: false,
      default: 'white_on_black',
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    smaller: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
