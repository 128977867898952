var render = function (_h,_vm) {var _c=_vm._c;return _c('div',_vm._b({class:[_vm.data.class, _vm.data.staticClass]},'div',_vm.data.attrs,false),[_c('figure',[_c('picture',[(_vm.props.file.breakpoints)?[_vm._l((_vm.props.file.breakpoints),function(breakpoint,key){return [(key + 1 < _vm.props.file.breakpoints.length)?[_c('source',{key:key + 'X',attrs:{"srcset":breakpoint.publicUrl,"type":breakpoint.mimeType,"media":breakpoint.props &&
                breakpoint.props.media &&
                breakpoint.props.size
                  ? ("(" + (breakpoint.props.media) + ": " + (breakpoint.props.size) + ")")
                  : null}})]:[_c('source',{key:key + 'Y',attrs:{"srcset":breakpoint.publicUrl,"type":breakpoint.mimeType,"media":breakpoint.props &&
                breakpoint.props.media &&
                breakpoint.props.size
                  ? ("(" + (breakpoint.props.media) + ": " + (breakpoint.props.size) + ")")
                  : null}}),_vm._v(" "),_c('img',{key:key + 'Z',class:_vm.$style['ce-media-image'],attrs:{"src":_vm.props.file.publicUrl,"alt":_vm.props.file.properties.alternative ||
                _vm.props.file.properties.title ||
                false,"title":_vm.props.file.properties.title || '',"height":_vm.props.file.properties.dimensions.height,"width":_vm.props.file.properties.dimensions.width,"type":_vm.props.file.properties.mimeType,"loading":"lazy"}})]]})]:[_c('img',{class:_vm.$style['ce-media-image'],attrs:{"src":_vm.props.file.publicUrl,"alt":_vm.props.file.properties.alternative ||
            _vm.props.file.properties.title ||
            false,"title":_vm.props.file.properties.title || '',"height":_vm.props.file.properties.dimensions.height,"width":_vm.props.file.properties.dimensions.width,"type":_vm.props.file.properties.mimeType,"loading":"lazy"}})]],2)]),_vm._v(" "),(_vm.props.caption && _vm.props.file.properties.description)?_c('figcaption',[_vm._v("\n    "+_vm._s(_vm.props.file.properties.description)+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }