//
//
//
//

import Cookiebot from './media/type/Cookiebot'
import MediaImage from './media/type/Image'
import MediaYoutube from './media/type/Youtube'
import MediaVimeo from './media/type/Vimeo'
import MediaVideo from './media/type/Video'
import MediaAudio from './media/type/Audio'

export default {
  name: 'MediaFile',
  components: {
    Cookiebot,
    MediaImage,
    MediaYoutube,
    MediaVimeo,
    MediaVideo,
    MediaAudio,
  },
  props: {
    file: {
      type: Object,
      required: true,
      default: () => {},
    },
    force: {
      type: Boolean,
      required: false,
      default: false,
    },
    caption: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  computed: {
    videoAllowed() {
      return this.$store.state.cookiebot.preferences
    },
  },
  methods: {
    getMediaType(file) {
      switch (file.type) {
        case 'video': {
          if (!this.videoAllowed && !this.force) return 'Cookiebot'

          if (file.mimeType.includes('youtube')) {
            return 'media-youtube'
          }
          if (file.mimeType.includes('vimeo')) {
            return 'media-vimeo'
          }
          return 'media-video'
        }
        case 'audio':
          return 'media-audio'
        default:
          return 'media-image'
      }
    },
  },
}
