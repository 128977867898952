//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CeMediaVimeo',
  functional: true,
  props: {
    file: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
}
